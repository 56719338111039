<template>
  <div class="pt-5">

    <section class="container mb-5">
      <div class="card shadow-sm border-0 mt-5">
        <div class="card-body">

          <div class="mb-4">
            <h2>Add Informasi Jabatan</h2>
            <h6>Tanda (*) Wajib diisi</h6>
          </div>

          <el-form :model="form" :rules="rules" ref="formInfoJabatan">
            <el-form-item prop="jnsjabatan">
              <h5>
                Jenis Jabatan*
                <i
                  class="bi bi-info-circle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-custom-class="custom-tooltip"
                  :data-bs-title="tooltipText[0]"
                ></i>
              </h5>
              <el-select
                v-model="form.jns_jabatan"
                placeholder="- Pilih Jenis Jabatan -"
                filterable
                no-match-text="Tidak ada data yang sesuai"
                no-data-text="Tidak ada data"
              >
                <el-option
                  v-for="item in master.jns_jabatan"
                  :key="item.id"
                  :label="`(${item.id}) ${item.name}`"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="sector">
              <h5>
                Kategori/Lapangan Usaha*
                <i
                  class="bi bi-info-circle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-custom-class="custom-tooltip"
                  :data-bs-title="tooltipText[0]"
                ></i>
              </h5>
              <el-select
                v-model="form.sektor"
                placeholder="- Pilih Kategori/Lapangan Usaha -"
                filterable
                no-match-text="Tidak ada data yang sesuai"
                no-data-text="Tidak ada data"
                @change="filterKbli"
              >
                <el-option
                  v-for="item in master.sector"
                  :key="item.id"
                  :label="`(${item.kode}) ${item.sector}`"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="kode_kbli">
              <h5>
                Kode KBLI*
                <i
                  class="bi bi-info-circle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-custom-class="custom-tooltip"
                  :data-bs-title="tooltipText[1]"
                ></i>
              </h5>
              <el-select
                :disabled="form.sektor ? false : true"
                v-model="form.kode_kbli"
                placeholder="- Pilih KBLI -"
                filterable
                clearable
                no-match-text="Tidak ada data yang sesuai"
                no-data-text="Tidak ada data"
                remote
                :remote-method="kbliSearch"
              >
                <el-option
                  v-for="item in kodeKbliOptions"
                  :key="item.kode"
                  :label="`${item.kode} - ${item.kbli}`"
                  :value="item.kode"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="nama_jabatan">
              <h5>
                Nama Jabatan*
                <i
                  class="bi bi-info-circle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-custom-class="custom-tooltip"
                  :data-bs-title="tooltipText[2]"
                ></i>
              </h5>
              <el-input
                placeholder=""
                v-model="form.nama_jabatan"
              >
              </el-input>
            </el-form-item>

            <el-form-item>
              <h5>
                Padanan Nama Jabatan
                <i
                  class="bi bi-info-circle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-custom-class="custom-tooltip"
                  :data-bs-title="tooltipText[3]"
                ></i>
              </h5>
              <el-input
                placeholder=""
                v-model="form.padana_nama_jabatan"
              >
              </el-input>
            </el-form-item>

            <el-form-item prop="kode_jabatan">
              <h5>
                Kode Jabatan*
                <i
                  class="bi bi-info-circle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-custom-class="custom-tooltip"
                  :data-bs-title="tooltipText[4]"
                ></i>
              </h5>
              <el-select
                v-model="form.kode_jabatan"
                placeholder="- Pilih Kode Jabatan -"
                filterable
                clearable
                no-match-text="Tidak ada data yang sesuai"
                no-data-text="Tidak ada data"
                remote
                :remote-method="kodeJabatanSearch"
                :loading="loadingKodeJabatanSearch"
                loading-text="Loading"
              >
                <el-option
                  v-for="item in master.kodeJabatan"
                  :key="item.kode"
                  :label="`${item.kode} - ${item.golongan}`"
                  :value="item.kode"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <h5>
                Ringkasan Uraian Tugas
                <i
                  class="bi bi-info-circle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-custom-class="custom-tooltip"
                  :data-bs-title="tooltipText[5]"
                ></i>
              </h5>
              <el-input
                type="textarea"
                :rows="4"
                placeholder=""
                v-model="form.ringkasan_uraian_tugas"
              >
              </el-input>
            </el-form-item>

            <el-form-item prop="rincian_tugas">
              <div class="d-flex justify-content-between">
                <h5>
                  Rincian Tugas*
                  <i
                    class="bi bi-info-circle"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-custom-class="custom-tooltip"
                    :data-bs-title="tooltipText[6]"
                  ></i>
                </h5>
                <el-button
                  style="background-color: #15406A; color: white;"
                  @click.prevent="addRincianTugas"
                >
                  Tambah Rincian Tugas
                </el-button>
              </div>
              <div
                v-show="showRincianTugas"
                v-for="(tugas, idx) in rincianTugas"
                :key="idx"
                class="pb-2"
              >
                <div class="d-flex justify-content-between my-2">
                  <el-input
                    v-model="form.rincian_tugas[idx]"
                    style="width: 96%"
                  >
                  </el-input>
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    @click="deleteRincianTugas(idx)"
                  >
                  </el-button>
                </div>
              </div>
            </el-form-item>

            <el-form-item prop="pendidikan">
              <h5>
                Pendidikan*
                <i
                  class="bi bi-info-circle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-custom-class="custom-tooltip"
                  :data-bs-title="tooltipText[7]"
                ></i>
              </h5>
              <el-select
                v-model="form.pendidikan"
                placeholder=""
                filterable
                clearable
                multiple
                no-match-text="Tidak ada data yang sesuai"
                no-data-text="Tidak ada data"
                remote
                :remote-method="pendidikanSearch"
              >
                <el-option
                  v-for="item in pendidikanOptions"
                  :key="item.kode"
                  :label="`(${item.kode}) ${item.pendidikan}`"
                  :value="item.kode"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="kompetensi">
              <h5>
                Kompetensi*
                <i
                  class="bi bi-info-circle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-custom-class="custom-tooltip"
                  :data-bs-title="tooltipText[16]"
                ></i>
              </h5>
              <el-select
                v-model="form.kompetensi"
                placeholder=""
                filterable
                clearable
                multiple
                no-match-text="Tidak ada data yang sesuai"
                no-data-text="Tidak ada data"
              >
                <el-option
                  v-for="item in master.kompetensi"
                  :key="item.kode"
                  :label="`(${item.kode}) ${item.kompetensi}`"
                  :value="item.kode"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="pengetahuankerja">
              <div class="d-flex justify-content-between">
                <h5>
                  Pengetahuan Kerja*
                  <i
                    class="bi bi-info-circle"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-custom-class="custom-tooltip"
                    :data-bs-title="tooltipText[8]"
                    ></i>
                </h5>
                <el-button
                  style="background-color: #15406A; color: white;"
                  @click="addPengetahuanKerja"
                >
                  Tambah Pengetahuan Kerja
                </el-button>
              </div>
              <div
                v-show="showPengetahuanKerja"
                v-for="(item, idx) in pengetahuanKerja"
                :key=idx
              >
                <div class="d-flex justify-content-between my-2">
                  <el-input
                    v-model="form.pengetahuankerja[idx]"
                    style="width: 96%"
                  >
                  </el-input>
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    @click="deletePengetahuanKerja(idx)"
                  >
                </el-button>
                </div>
              </div>
            </el-form-item>
            
            <el-form-item prop="pelatihankerja">
              <div class="d-flex justify-content-between">
                <h5>
                  Pelatihan Kerja*
                  <i
                    class="bi bi-info-circle"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-custom-class="custom-tooltip"
                    :data-bs-title="tooltipText[9]"
                  ></i>
                </h5>
                <el-button
                  style="background-color: #15406A; color: white;"
                  @click.prevent="addPelatihanKerja"
                >
                  Tambah Pelatihan Kerja
                </el-button>
              </div>
              <div
                v-show="showPelatihanKerja"
                v-for="(pelatihan, idx) in pelatihanKerja"
                :key=idx
              >
                <div class="d-flex justify-content-between my-2">
                  <el-input
                    v-model="form.pelatihankerja[idx]"
                    style="width: 96%"
                  >
                  </el-input>
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    @click.prevent="deletePelatihanKerja(idx)"
                  >
                </el-button>
                </div>
              </div>
            </el-form-item>

            <el-form-item prop="syaratfisik">
              <h5>
                Upaya Fisik*
                <i
                  class="bi bi-info-circle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-custom-class="custom-tooltip"
                  :data-bs-title="tooltipText[10]"
                ></i>
              </h5>
              <el-select
                v-model="form.syaratfisik"
                placeholder=""
                filterable
                clearable
                multiple
                no-match-text="Tidak ada data yang sesuai"
                no-data-text="Tidak ada data"
              >
                <el-option
                  v-for="item in master.syaratFisik"
                  :key="item.kode"
                  :label="`(${item.kode}) ${item.upayafisik}`"
                  :value="item.kode"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="bakat">
              <h5>
                Bakat*
                <i
                  class="bi bi-info-circle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-custom-class="custom-tooltip"
                  :data-bs-title="tooltipText[11]"
                ></i>
              </h5>
              <el-select
                v-model="form.bakat"
                filterable
                clearable
                multiple
                placeholder=""
                no-match-text="Tidak ada data yang sesuai"
                no-data-text="Tidak ada data"
              >
                <el-option
                  v-for="item in master.bakat"
                  :key="item.kode"
                  :label="`(${item.kode}) ${item.bakat}`"
                  :value="item.kode"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="minat">
              <h5>
                Minat*
                <i
                  class="bi bi-info-circle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-custom-class="custom-tooltip"
                  :data-bs-title="tooltipText[12]"
                ></i>
              </h5>
              <el-select
                v-model="form.minat"
                filterable
                clearable
                multiple
                placeholder=""
                no-match-text="Tidak ada data yang sesuai"
                no-data-text="Tidak ada data"
              >
                <el-option
                  v-for="item in master.minat"
                  :key="item.kode"
                  :label="`(${item.kode}) ${item.minat}`"
                  :value="item.kode"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="tempramen">
              <h5>
                Temperamen*
                <i
                  class="bi bi-info-circle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-custom-class="custom-tooltip"
                  :data-bs-title="tooltipText[13]"
                ></i>
              </h5>
              <el-select
                v-model="form.tempramen"
                filterable
                clearable
                multiple
                placeholder=""
                no-match-text="Tidak ada data yang sesuai"
                no-data-text="Tidak ada data"
              >
                <el-option
                  v-for="item in master.tempramen"
                  :key="item.kode"
                  :label="`(${item.kode}) ${item.temperamen}`"
                  :value="item.kode"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <h5>
                Tahun Jabatan
                <i
                  class="bi bi-info-circle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-custom-class="custom-tooltip"
                  :data-bs-title="tooltipText[14]"
                ></i>
              </h5>
              <el-input
                placeholder=""
                v-model="tahunJabatan"
                :disabled="true"
              >
              </el-input>
            </el-form-item>

            <el-form-item>
              <h5>
                Catatan
                <i
                  class="bi bi-info-circle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-custom-class="custom-tooltip"
                  :data-bs-title="tooltipText[15]"
                ></i>
              </h5>
              <el-input
                type="textarea"
                :rows="4"
                placeholder=""
                v-model="form.catatan"
              >
              </el-input>
            </el-form-item>

            <el-form-item class="d-flex justify-content-end">
              <el-button
                style="background-color: #15406A; color: white;"
                @click="addForm"
              >
                Submit Usulan
              </el-button>
            </el-form-item>

          </el-form>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from './api'
import swal from 'sweetalert2'
import apiSearch from '../search/api.js'
import auth from '../../helpers/auth.js'
import {
  Select,
  Option,
  Loading,
  Input,
  Radio,
  RadioGroup,
  Button,
  Form,
  FormItem
} from 'element-ui'

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Loading.name]: Loading,
    [Input.name]: Input,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem
  },
  data () {
    return {
      data: [],
      master: {
        jns_jabatan:[],
        sector: [],
        subsector: [],
        kodeKbli: [],
        kodeJabatan: [],
        pendidikan: [],
        syaratFisik: [],
        bakat: [],
        minat: [],
        tempramen: [],
        kompetensi: []
      },
      kodeKbliOptions: [],
      pendidikanOptions: [],

      selectedSector: '',
      selectedSubsector: '',
      selectedKodeKbli: '',
      namaJabatan: '',
      padananNamaJabatan: '',
      selectedKodeJabatan: '',
      ringkasanTugas: '',
      selectedPendidikan: [],
      selectedKomptensi: [],
      selectedFisik: [],
      selectedBakat: [],
      selectedMinat: [],
      selectedTempramen: [],

      tahunJabatan: new Date().getFullYear(),
      catatan: '',
      showRincianTugas: false,
      rincianTugas: [],
      showPengetahuanKerja: false,
      pengetahuanKerja: [],
      showPelatihanKerja: false,
      pelatihanKerja: [],
      isLoading: false,
      loadingKodeJabatanSearch: false,
      tooltipTriggerList: null,
      tooltipList: null,
      tooltipText: [
        'Berdasarkan KBLI 2020',
        'Identitas atau asal kategori/lapangan usaha suatu jabatan sampai dengan digit ke-5 menurut KBLI 2020',
        'Indentitas singkat mengenai suatu jabatan yang memberi ciri dan gambaran atas isi jabatan yang berupa sekelompok tugas yang melembaga atau menyatu dalam suatu wadah jabatan',
        'Nama/sebutan lain dari Nama Jabatan yang sejenis atau memiliki makna yang (hampir) sama',
        'Pembeda dengan jabatan lain dan untuk memudahkan dalam pengadministrasiannya Kode Jabatan mengacu kepada ISCO (4 digit) dan KBJI (6 digit)',
        'Merupakan uraian singkat yang menggambarkan ruang lingkup  tugas jabatan yang disusun  dalam satu kalimat',
        'Paparan atau bentangan atas semua tugas jabatan yang merupakan upaya pokok yang dilakukan pemegang jabatan dalam memproses bahan kerja menjadi hasil kerja dengan menggunakan perangkat kerja dan dalam kondisi pelaksanaan tertentu',
        'Dinyatakan menurut tingkat dan jenisnya yang dibuktikan dengan sertifikat atau ijasah',        
        'Kualifikasi yang harus dimiliki oleh pemegang jabatan untuk bisa melakukan pekerjaan dengan sebaik-baiknya',
        'Kegiatan terencana dan kontinyu untuk memperoleh keterampilan kerja',
        'Penggunaan fisik meliputi penggunaan organ tubuh  tangan, kaki, lengan, telapak tangan serta jari tangan, sendi pergelangan tangan, lengan, bahu, punggung, mata, telinga, hidung dan mulut',
        'Kapasitas potensial minimal yang dimiliki seseorang agar ia dapat melaksanakan tindak kerja sesuai dengan tuntutan tugas jabatan',
        'Kecenderungan untuk terserap dalam suatu pengalaman dan mengembangkannya, sedangkan keengganan, merupakan kecenderungan untuk menghindari sesuatu',
        'Kemampuan menyesuaikan diri yang harus dipenuhi pekerja untuk bekerja sesuai data jabatan',
        'Tahun jabatan',
        'Tambahkan keterangan bila jabatan ini dapat diduduki oleh Tenaga Kerja Asing atau Penyandang Disabilitas, atau keterangan lain yang belum terdapat dalam Kertas Kerja sebagai informasi tambahan untuk Kemnaker',
        'Kompetensi yang harus dimiliki oleh pekerja untuk bisa melakukan pekerjaan dengan sebaik-baiknya'
      ],
      form: {
        sektor: '',
        subsector: '',
        kode_kbli: '',
        nama_jabatan: '',
        padanan_nama_jabatan: '',
        kode_jabatan: '',
        ringkasan_uraian_tugas: '',
        jns_jabatan: '',
        pendidikan: [],
        kompetensi: [],
        syaratfisik: [],
        bakat: [],
        minat: [],
        temperamen: [],
        tahun_jabatan: new Date().getFullYear(),
        catatan: '',
        rincian_tugas: [],
        pengetahuankerja: [],
        pelatihankerja: []
      },
      rules: {
        jns_jabatan: [{ required: true, message: 'Pilih salah satu Jenis Jabatan', trigger: 'change' }],
        sektor: [{ required: true, message: 'Pilih salah satu Kategori/Lapangan Usaha', trigger: 'change' }],
        kode_kbli: [{ required: true, message: 'Pilih salah satu Kode KBLI', trigger: 'change' }],
        nama_jabatan: [{ required: true, message: 'Nama Jabatan tidak boleh kosong', trigger: 'blur' }],
        kode_jabatan: [{ required: true, message: 'Pilih salah satu Kode Jabatan', trigger: 'change' }],
        rincian_tugas: [{ type: 'array', required: true, message: 'Masukkan minimal satu Rincian Tugas', trigger: 'change' }],
        // inputRincian: [{ required: true, message: 'Rincian Tugas tidak boleh kosong', trigger: 'blur' }],
        pendidikan: [{ type: 'array', required: true, message: 'Pilih minimal satu Pendidikan', trigger: 'change' }],
        kompetensi: [{ type: 'array', required: true, message: 'Pilih minimal satu Kompetensi', trigger: 'change' }],
        pengetahuankerja: [{ type: 'array', required: true, message: 'Masukkan minimal satu Pengetahuan Kerja', trigger: 'change' }],
        pelatihankerja: [{ type: 'array', required: true, message: 'Masukkan minimal satu Pelatihan Kerja', trigger: 'change' }],
        syaratfisik: [{ type: 'array', required: true, message: 'Pilih minimal satu Upaya Fisik', trigger: 'change' }],
        bakat: [{ type: 'array', required: true, message: 'Pilih minimal satu Bakat', trigger: 'change' }],
        minat: [{ type: 'array', required: true, message: 'Pilih minimal satu Minat', trigger: 'change' }],
        temperamen: [{ type: 'array', required: true, message: 'Pilih minimal satu Temperamen', trigger: 'change' }],        
      }
    }
  },
  computed: {
    // form () {
    //   const form = {
    //     sector: this.selectedSector,
    //     subsector: this.selectedSubsector,
    //     kode_kbli: this.selectedKodeKbli,
    //     nama_jabatan: this.namaJabatan,
    //     padanan_nama_jabatan: this.padananNamaJabatan,
    //     kode_jabatan: this.selectedKodeJabatan,
    //     ringkasan_uraian_tugas: this.ringkasanTugas,
    //     pendidikan: this.selectedPendidikan,
    //     syaratfisik: this.selectedFisik,
    //     bakat: this.selectedBakat,
    //     minat: this.selectedMinat,
    //     temperamen: this.selectedTempramen,
    //     tahun_jabatan: this.tahunJabatan,
    //     catatan: this.catatan,
    //     rincian_tugas: this.rincianTugas,
    //     pengetahuankerja: this.pengetahuanKerja,
    //     pelatihankerja: this.pelatihanKerja
    //   }
    //   return form
    // },
    validate () {
      const error = []
      if (!this.selectedJenisJabatan) {
        error.push('Jenis Jabatan wajib diisi')
      }
      if (!this.selectedSector) {
        error.push('Kategori Lapangan / Usaha wajib diisi')
      }
      if (!this.padananNamaJabatan || !this.padananNamaJabatan.trim()) {
        error.push('Padanan Nama Jabatan wajib diisi')
      }
      if (!this.ringkasanTugas || !this.ringkasanTugas.trim()) {
        error.push('Ringkasan Uraian Tugas wajib diisi')
      }
      if (this.rincianTugas.length === 0) {
        error.push('Rincian Tugas wajib diisi')
      }
      if (this.pengetahuanKerja.length === 0) {
        error.push('Pengetahuan Kerja wajib diisi')
      }
      if (this.pelatihanKerja.length === 0) {
        error.push('Pelatihan Kerja wajib diisi')
      }
      if (!this.tahunJabatan || !this.tahunJabatan.trim()) {
        error.push('Tahun Jabatan wajib diisi')
      }
      if (!this.catatan || !this.catatan.trim()) {
        error.push('Catatan wajib diisi')
      }
      return error
    },
    filteredSubsector () {
      if (this.form.sector) {
        return this.master.subsector.filter(item => item.sector_id === this.form.sector)
      }
      return this.master.subsector
    },
    filteredKbli () {
      if (this.form.sector) {
        return this.master.kodeKbli.filter(item => item.sector_id === this.form.sector)
      }
      return this.master.kodeKbli
    },
    filteredJabatan () {
      if (this.form.sector) {
        return this.master.kodeJabatan.filter(item => item.sector_id === this.form.sector)
      }
      return this.master.kodeJabatan
    }
  },
  mounted () {
    this.getMasterData()
    this.tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    this.tooltipList = [...this.tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
  },
  updated () {
    this.tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    this.tooltipList = [...this.tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
  },
  methods: {
    async getMasterData () {
      Loading.service()
      try {
        const { data: sectorData } = await api.getSector()
        const { data: kbliData } = await api.getKbliData()
        const { data: jabatanData } = await apiSearch.getSearchKbji({ limit: 25 })
        const data = await apiSearch.phpGetSuntingMaster()
        this.master.jns_jabatan = [{ id: 1, name: 'Tenaga Kerja Umum' }, { id: 2, name: 'Tenaga Kerja Asing (TKA)' }]
        this.master.sector = sectorData
        this.master.kodeKbli = kbliData
        this.kodeKbliOptions = kbliData.slice(0, 50)
        this.master.kodeJabatan = jabatanData
        this.master.pendidikan = data[0].data
        this.pendidikanOptions = data[0].data.slice(0, 25)
        this.master.syaratFisik = data[1].data
        this.master.bakat = data[2].data
        this.master.minat = data[3].data
        this.master.tempramen = data[4].data
        this.master.kompetensi = data[5].data
      } catch (error) {
        //console.log(error)
      } finally {
        Loading.service().close()
      }
    },
    addRincianTugas () {
      this.showRincianTugas = true
      this.form.rincian_tugas.push('')
      this.rincianTugas.push(this.rincianTugas.length)
    },
    deleteRincianTugas (idx) {
      const newArr = JSON.parse(JSON.stringify(this.form.rincian_tugas))
      newArr.splice(idx, 1)

      const newArr2 = JSON.parse(JSON.stringify(this.rincianTugas))
      newArr2.splice(idx, 1)

      this.form.rincian_tugas = JSON.parse(JSON.stringify(newArr))
      this.rincianTugas = JSON.parse(JSON.stringify(newArr2))
    },
    addPengetahuanKerja () {
      this.showPengetahuanKerja = true
      this.form.pengetahuankerja.push('')
      this.pengetahuanKerja.push(this.pengetahuanKerja.length)
      // this.showPengetahuanKerja = true
      // this.pengetahuanKerja.push({
      //   id: this.pengetahuanKerja.length === 0 ? 1 : this.pengetahuanKerja[this.pengetahuanKerja.length - 1].id + 1,
      //   description: ''
      // })
    },
    deletePengetahuanKerja (idx) {
      const newArr = JSON.parse(JSON.stringify(this.form.pengetahuankerja))
      newArr.splice(idx, 1)

      const newArr2 = JSON.parse(JSON.stringify(this.pengetahuanKerja))
      newArr2.splice(idx, 1)

      this.form.pengetahuankerja = JSON.parse(JSON.stringify(newArr))
      this.pengetahuanKerja = JSON.parse(JSON.stringify(newArr2))
    },
    addPelatihanKerja () {
      this.showPelatihanKerja = true
      this.form.pelatihankerja.push('')
      this.pelatihanKerja.push(this.pelatihanKerja.length)
      // this.pelatihanKerja.push({
      //   id: this.pelatihanKerja.length === 0 ? 1 : this.pelatihanKerja[this.pelatihanKerja.length - 1].id + 1,
      //   description: ''
      // })
    },
    deletePelatihanKerja (idx) {
      const newArr = JSON.parse(JSON.stringify(this.form.pelatihankerja))
      newArr.splice(idx, 1)

      const newArr2 = JSON.parse(JSON.stringify(this.pelatihanKerja))
      newArr2.splice(idx, 1)

      this.form.pelatihankerja = JSON.parse(JSON.stringify(newArr))
      this.pelatihanKerja = JSON.parse(JSON.stringify(newArr2))

      // this.pelatihanKerja = this.pelatihanKerja.filter(item => item.id !== id)
    },
    async addForm () {
      this.$refs.formInfoJabatan.validate(async (valid) => {
        let loadprg = null
        try {
          //console.log('valid', valid)
          if (valid === false) {
            swal.fire({
              icon: 'warning',
              text: 'Mohon lengkapi form usulan telebih dahulu'
            })
            return ''
          } else {
            loadprg = Loading.service()
            // Loading.service()
           //console.log('ini form', this.form)
            const user = auth.getUser()
            const payload = {
              ...this.form,
              email: user.email,
              token: auth.getSSOToken()
            }
            //console.log('usulan payload', payload)
            const res = await api.submitUsulan(payload, { Authorization: `Bearer ${auth.getToken()}` })
            swal.fire({
              icon: 'success',
              title: 'Berhasil submit usulan jabatan',
              // text: 'Berhasil submit usulan jabatan'
            })
            this.$router.push({ name: 'Hero' })
          }
        } catch (err) {
          //console.log('err', err)
          swal.fire({
            icon: 'error',
            text: 'Gagal melakukan submit usulan'
          })
        } finally {
          // Loading.service().close()
          if (loadprg !== null) {
            loadprg.close()
          }
        }
      })
    },
    kbliSearch (query) {
      if (query === '') {
        this.kodeKbliOptions = this.master.kodeKbli.slice(0, 50)
      } else {
        this.kodeKbliOptions = this.master.kodeKbli.filter(data => data.kbli.toLowerCase().includes(query.toLowerCase())).slice(0, 50)
      }
    },
    filterKbli () {
      this.kodeKbliOptions = this.master.kodeKbli.filter(item => item.sector_id === this.form.sector).slice(0, 50)
    },
    pendidikanSearch (query) {
      if (query === '') {
        this.pendidikanOptions = this.master.pendidikan.slice(0, 25)
      } else {
        this.pendidikanOptions = this.master.pendidikan.filter(data => data.pendidikan.toLowerCase().includes(query.toLowerCase())).slice(0, 25)
      }
    },
    
    async kodeJabatanSearch (query) {
      try {
        const params = { limit: 25, keyword: query }
        this.loadingKodeJabatanSearch = true
        const { data } = await apiSearch.getSearchKbji(params)
        this.master.kodeJabatan = data
      } catch (err) {
        //console.log('err', err)
        swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error when fetching kode jabatan'
        })
      } finally {
        this.loadingKodeJabatanSearch = false
      }
    }
  }
}
</script>

<style>
.el-select-dropdown {
  z-index: 9001 !important;
}

.el-select {
  /* display: block;
  padding-right: 8px; */
  width: 100%;
}
.tooltip-inner {
  text-align: left;
  max-width: 20vw !important;
}
</style>
<style scoped>
.form-section {
  margin-bottom: 15px;
}
</style>
