import axios from 'axios'
const phpAddressLocal = 'http://127.0.0.1:8000'
// const phpAddress = 'http://127.0.0.1:8000'
const phpAddress = process.env.VUE_APP_PHP_API

// express
const client = axios.create({
  baseURL: 'http://localhost:8082/',
  json: true
})

// php
const clientPhp = axios.create({
  baseURL: phpAddress,
  json: true
})
const clientPhpLocal = axios.create({
  baseURL: phpAddressLocal,
  json: true
})

export default {
  async execute(method, resource, data) {
    return client({
      method,
      url: resource,
      data
    }).then(req => {
      return req.data
    })
  },
  async executePhp(method, resource, data, headers) {
    return clientPhp({
      method,
      url: resource,
      data,
      headers
    }).then(req => {
      return req.data
    })
  },
  async executePhpLocal(method, resource, data) {
    return clientPhpLocal({
      method,
      url: resource,
      data
    }).then(req => {
      return req.data
    })
  },
  quickSearch (keyword) {
    return this.execute('get', `/api/simpleSearch/${keyword}`)
  },
  quickSearch2 (keyword = '', limit, offset) {
    return this.execute('get', `/api/simpleSearch/${keyword}/${limit}/${offset}`)
  },
  quickSearch3 (params) {
    if (params.keyword !== '' && params.keyword) {
      return this.execute('get', `/api/search/simpleSearch/${params.keyword}/${params.limit}/${params.offset}`)
    } else {
      return this.execute('get', `/api/search/simpleSearch/${params.limit}/${params.offset}`)
    }
  },
  quickSearchPhpNonKeyword (param, address = phpAddress + '/api/kjn/jabatan/all') {
    return axios.post(address, param)
  },
  quickSearchTkaPhpNonKeyword (param, address = phpAddress + '/api/kjn/tka/jabatan/all') {
    return axios.post(address, param)
  },
  quickSearchDisabilitasPhpNonKeyword (param, address = phpAddress + '/api/kjn/disabilitas/jabatan/all') {
    return axios.post(address, param)
  },
  quickSearchPhpKeyword (param, keyword, nextPage = null) {
    const initialAddress = phpAddress + '/api/kjn/search/jabatan/index/' + keyword
    let address = nextPage !== null && nextPage !== '' ? nextPage : initialAddress
    return axios.post(address, param)
  },
  quickSearchTkaPhpKeyword (param, keyword, nextPage = null) {
    const initialAddress = phpAddress + '/api/kjn/tka/search/jabatan/index/' + keyword
    let address = nextPage !== null && nextPage !== '' ? nextPage : initialAddress
    return axios.post(address, param)
  },
  quickSearchDisabilitasPhpKeyword (param, keyword, nextPage = null) {
    const initialAddress = phpAddress + '/api/kjn/disabilitas/search/jabatan/index/' + keyword
    let address = nextPage !== null && nextPage !== '' ? nextPage : initialAddress
    return axios.post(address, param)
  },
  advanceSearch (param, nextPage = null) {
    const initialAddress = phpAddress + `/api/kjn/search/jabatan/advance`
    let address = nextPage !== null && nextPage !== '' ? nextPage : initialAddress
    return axios.post(address, param)
  },
  advanceTkaSearch (param, nextPage = null) {
    const initialAddress = phpAddress + `/api/kjn/tka/search/jabatan/advance`
    let address = nextPage !== null && nextPage !== '' ? nextPage : initialAddress
    return axios.post(address, param)
  },
  advanceDisabilitasSearch (param, nextPage = null) {
    const initialAddress = phpAddress + `/api/kjn/disabilitas/search/jabatan/advance`
    let address = nextPage !== null && nextPage !== '' ? nextPage : initialAddress
    return axios.post(address, param)
  },
  getDetailPhp (jabatanId) {
    return this.executePhp('get', `/api/kjn/jabatan/detail/${jabatanId}`)
  },
  getDetailTkaPhp (jabatanId) {
    return this.executePhp('get', `/api/kjn/tka/jabatan/detail/${jabatanId}`)
  },
  getDetailDisabilitasPhp (jabatanId) {
    return this.executePhp('get', `/api/kjn/disabilitas/jabatan/detail/${jabatanId}`)
  },
  getDetail (informationId) {
    const allReq = [
      this.execute('get', `/api/details/subHeader/${informationId}`),
      this.execute('get', `/api/details/rincianTugas/${informationId}`),
      this.execute('get', `/api/details/pendidikan/${informationId}`),
      this.execute('get', `/api/details/pengetahuanKerja/${informationId}`),
      this.execute('get', `/api/details/pelatihanKerja/${informationId}`),
      this.execute('get', `/api/details/upayaFisik/${informationId}`),
      this.execute('get', `/api/details/bakat/${informationId}`),
      this.execute('get', `/api/details/minat/${informationId}`),
      this.execute('get', `/api/details/tempramen/${informationId}`),
      this.execute('get', `/api/details/kompetensi/${informationId}`),
    ]

    return axios.all(allReq)
  },
  getDetailTka (informationId) {
    const allReq = [
      this.execute('get', `/api/tka/details/subHeader/${informationId}`),
      this.execute('get', `/api/tka/details/rincianTugas/${informationId}`),
      this.execute('get', `/api/tka/details/pendidikan/${informationId}`),
      this.execute('get', `/api/tka/details/pengetahuanKerja/${informationId}`),
      this.execute('get', `/api/tka/details/pelatihanKerja/${informationId}`),
      this.execute('get', `/api/tka/details/upayaFisik/${informationId}`),
      this.execute('get', `/api/tka/details/bakat/${informationId}`),
      this.execute('get', `/api/tka/details/minat/${informationId}`),
      this.execute('get', `/api/tka/details/tempramen/${informationId}`),
      this.execute('get', `/api/tka/details/kompetensi/${informationId}`),
    ]

    return axios.all(allReq)
  },
  getDetailDisabilitas (informationId) {
    const allReq = [
      this.execute('get', `/api/disabilitas/details/subHeader/${informationId}`),
      this.execute('get', `/api/disabilitas/details/rincianTugas/${informationId}`),
      this.execute('get', `/api/disabilitas/details/pendidikan/${informationId}`),
      this.execute('get', `/api/disabilitas/details/pengetahuanKerja/${informationId}`),
      this.execute('get', `/api/disabilitas/details/pelatihanKerja/${informationId}`),
      this.execute('get', `/api/disabilitas/details/upayaFisik/${informationId}`),
      this.execute('get', `/api/disabilitas/details/bakat/${informationId}`),
      this.execute('get', `/api/disabilitas/details/minat/${informationId}`),
      this.execute('get', `/api/disabilitas/details/tempramen/${informationId}`),
      this.execute('get', `/api/disabilitas/details/kompetensi/${informationId}`),
      this.execute('get', `/api/disabilitas/details/ragamdisabilitas/${informationId}`),
    ]

    return axios.all(allReq)
  },
  phpGetSuntingMaster () {
    const params1 = { limit: 999 }
    const params2 = { limit: 25 }
    const allReq = [
      this.executePhp('post', `/api/kjn/master/pendidikan/all`, params1),
      this.executePhp('post', `/api/kjn/master/upayafisik/all`, params1),
      this.executePhp('post', `/api/kjn/master/bakat/all`, params1),
      this.executePhp('post', `/api/kjn/master/minat/all`, params1),
      this.executePhp('post', `/api/kjn/master/temperamen/all`, params1),
      this.executePhp('post', `/api/kjn/master/kompetensi/all`, params1),
      this.executePhp('post', `/api/kjn/master/ragamdisabilitas/all`, params1)
    ]

    return axios.all(allReq)
  },

  phpGetSuntingTkaMaster () {
    const params1 = { limit: 999 }
    const params2 = { limit: 25 }
    const allReq = [
      this.executePhp('post', `/api/kjn/master/pendidikan/all`, params1),
      this.executePhp('post', `/api/kjn/master/upayafisik/all`, params1),
      this.executePhp('post', `/api/kjn/master/bakat/all`, params1),
      this.executePhp('post', `/api/kjn/master/minat/all`, params1),
      this.executePhp('post', `/api/kjn/master/temperamen/all`, params1),
      this.executePhp('post', `/api/kjn/master/kompetensi/all`, params1),
      this.executePhp('post', `/api/kjn/master/ragamdisabilitas/all`, params1)
    ]

    return axios.all(allReq)
  },
  phpGetSuntingDisabilitasMaster () {
    const params1 = { limit: 999 }
    const params2 = { limit: 25 }
    const allReq = [
      this.executePhp('post', `/api/kjn/master/pendidikan/all`, params1),
      this.executePhp('post', `/api/kjn/master/upayafisik/all`, params1),
      this.executePhp('post', `/api/kjn/master/bakat/all`, params1),
      this.executePhp('post', `/api/kjn/master/minat/all`, params1),
      this.executePhp('post', `/api/kjn/master/temperamen/all`, params1),
      this.executePhp('post', `/api/kjn/master/kompetensi/all`, params1),
      this.executePhp('post', `/api/kjn/master/ragamdisabilitas/all`, params1)
    ]

    return axios.all(allReq)
  },
  expressRemoteMasterSearch (keyword = '', master = 'pendidikan') {
    return this.execute('post', `/api/search/master/${master}${keyword ? '?keyword=' + keyword : '' }`)
  },
  submitSunting (payload, headers) {
    return this.executePhp('post', `/api/kjn/sunting/jabatan`, payload, headers)
  },
  submitSuntingtka (payload, headers) {
    return this.executePhp('post', `/api/kjn/tka/sunting/jabatan`, payload, headers)
  },
  submitSunting2 (payloads, middlewarePayload, headers) {
    let allReq = []
    payloads.forEach(payload => {
      allReq.push(this.executePhp('post', `/api/kjn/sunting/jabatan`, { ...payload, ...middlewarePayload }, headers))
    })
    return axios.all(allReq)
  },
  submitSunting2tka (payloads, middlewarePayload, headers) {
    let allReq = []
    payloads.forEach(payload => {
      allReq.push(this.executePhp('post', `/api/kjn/tka/sunting/jabatan`, { ...payload, ...middlewarePayload }, headers))
    })
    return axios.all(allReq)
  },
  submitSuntingLocal (payload) {
    return this.executePhpLocal('post', `/api/kjn/sunting/jabatan`, payload)
  },
  submitSuntingLocaltka (payload) {
    return this.executePhpLocal('post', `/api/kjn/tka/sunting/jabatan`, payload)
  },
  submitSuntingLocal2 (payloads) {
    let allReq = []
    payloads.forEach(payload => {
      allReq.push(this.executePhpLocal('post', `/api/kjn/sunting/jabatan`, payload))
    })
    return axios.all(allReq)
    // return this.executePhpLocal('post', `/api/kjn/sunting/jabatan`, payload)
  },
  submitSuntingLocal2tka (payloads) {
    let allReq = []
    payloads.forEach(payload => {
      allReq.push(this.executePhpLocal('post', `/api/kjn/tka/sunting/jabatan`, payload))
    })
    return axios.all(allReq)
    // return this.executePhpLocal('post', `/api/kjn/sunting/jabatan`, payload)
  },
  draftSunting (payloads, middlewarePayload, headers) {
    let allReq = []
    payloads.forEach(payload => {
      allReq.push(this.executePhp('post', `/api/kjn/sunting/draft/jabatan`, { ...payload, ...middlewarePayload }, headers))
    })
    return axios.all(allReq)
  },
  draftSuntingtka (payloads, middlewarePayload, headers) {
    let allReq = []
    payloads.forEach(payload => {
      allReq.push(this.executePhp('post', `/api/kjn/tka/sunting/draft/jabatan`, { ...payload, ...middlewarePayload }, headers))
    })
    return axios.all(allReq)
  },
  draftSuntingLocal (payloads) {
    let allReq = []
    payloads.forEach(payload => {
      allReq.push(this.executePhp('post', `/api/kjn/sunting/draft/jabatan`, payload))
    })
    return axios.all(allReq)
  },
  draftSuntingLocaltka (payloads) {
    let allReq = []
    payloads.forEach(payload => {
      allReq.push(this.executePhp('post', `/api/kjn/tka/sunting/draft/jabatan`, payload))
    })
    return axios.all(allReq)
  },
  submitDraftSunting (params, headers) {
    return this.executePhp('put', `/api/kjn/sunting/draft/status/new`, params, headers)
  },
  submitDraftSuntingtka (params, headers) {
    return this.executePhp('put', `/api/kjn/tka/sunting/draft/status/new`, params, headers)
  },
  submitDraftSuntingLocal (params) {
    return this.executePhpLocal('put', `/api/kjn/sunting/draft/status/new`, params)
  },
  submitDraftSuntingLocaltka (params) {
    return this.executePhpLocal('put', `/api/kjn/tka/sunting/draft/status/new`, params)
  },
  editSunting (s_log_id, params,  headers) {
    return this.executePhp('put', `/api/kjn/sunting/jabatan/${s_log_id}`, params, headers)
  },
  editSuntingtka (s_log_id, params,  headers) {
    return this.executePhp('put', `/api/kjn/tka/sunting/jabatan/${s_log_id}`, params, headers)
  },
  getSearchKbji (params) {
    return this.executePhp('post', `/api/kbji2014/search/jabatan/all`, params)
  },
  login (params) {
    return this.executePhp('post', '/api/auth/login', params)
  }
}
